function getDivision(level) {
  switch (level) {
    case 5:
      return 'Chpts';
    case 4:
      return 'N1';
    case 3:
      return 'N2';
    case 2:
      return 'N3';
    default:
      return 'Reg';
  }
}

function getEventName(eventName, place) {
  let result = eventName || '';
  result += place ? ` (${place})` : '';
  return result.replace(/&#039;/gi, '\'');
}

function getRaceName(eventName, place, raceName) {
  let result = getEventName(eventName, place);
  result += raceName ? ` - ${raceName}` : '';
  return result.replace(/&#039;/gi, '\'');
}

export { getDivision, getRaceName, getEventName };
